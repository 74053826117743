<!--
.. Mofified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <v-container fluid>
    <new-edit-sheet />
    <v-row align="center" justify="space-between" no-gutters>
      <v-col cols="8">
        <settings-breadcrumbs v-model="project" />
      </v-col>
      <v-col class="text-right">
        <v-btn color="info" class="mr-2" @click="createEditShow()">
          <v-icon class="mr-1">mdi-plus</v-icon>
          New OCO Type
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-alert outlined dismissible icon="mdi-format-superscript" prominent type="info">
          Types categorize OCOs. UnStruct allows for configuration on a per-OCO type basis, enabling tailored management and response strategies for different scenarios.
        </v-alert>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-card elevation="0">
          <v-card-title>
            <v-text-field v-model="q" append-icon="search" label="Search" single-line hide-details clearable />
          </v-card-title>
          <v-row>
          <v-col cols="12" sm="6" md="4" v-for="item in items" :key="item.id">
            <v-card class="ma-4 elevation-2">
              <!-- Item Name and Status -->
              <v-card-title>
                <v-icon large left>mdi-format-superscript</v-icon>{{ item.name }}
                <v-spacer></v-spacer>
                <v-chip v-if="item.default" color="success" dark class="ml-2">
                  <v-icon left>mdi-star</v-icon>
                  Default
                </v-chip>
                <v-chip v-if="item.enabled" color="blue" dark class="ml-2">
                  <v-icon left>mdi-lightbulb-on-outline</v-icon>
                  Enabled
                </v-chip>
                <v-chip v-if="!item.enabled" color="error" dark class="ml-2">
                  <v-icon left>mdi-lightbulb-off-outline</v-icon>
                  Disabled
                </v-chip>
              </v-card-title>

              <v-card-subtitle class="mb-2 mt-2">
                <v-chip v-if="item.storycurve_logging_enabled" color="warning" dark class="mb-2">
                  <v-icon left>mdi-strategy</v-icon>
                  StoryCurve Logging Enabled
                </v-chip>
                <div>
                <v-chip v-if="item.visibility === 'Open'" color="secondary" dark class="mb-2">
                  <v-icon left>mdi-earth</v-icon>
                  Public
                </v-chip>
              </div>
              <div>
                <v-chip v-if="item.visibility === 'Restricted'" color="error" dark>
                  <v-icon left>mdi-lock</v-icon>
                  Private
                </v-chip>
              </div>
              </v-card-subtitle>

              <v-card-text>
                <div>
                  <v-icon left>mdi-briefcase-check</v-icon>
                  OCO Type: {{ item.description }}
                </div>
                <div>
                  <v-icon left>mdi-axis-arrow-info</v-icon>
                  Description: {{ item.description }}
                </div>
                <div v-if="item.oncall_service">
                  <v-icon left>mdi-phone-in-talk</v-icon>
                  Oncall Service: {{ item.oncall_service.name }}
                  <service :service="item.oncall_service" />
                </div>
                <div>
                  <v-icon left>mdi-domain</v-icon>
                  IAO Type: {{ item.incident_type.name }}
                </div>
              </v-card-text>

              <!-- Actions -->
              <v-card-actions class="justify-end">
                <v-btn @click="createEditShow(item)" text color="primary">View / Edit</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapFields } from "vuex-map-fields"
import { mapActions } from "vuex"

import SettingsBreadcrumbs from "@/components/SettingsBreadcrumbs.vue"
import NewEditSheet from "@/case/type/NewEditSheet.vue"
import Service from "@/service/Service.vue"

export default {
  name: "CaseTypeTable",

  components: {
    NewEditSheet,
    SettingsBreadcrumbs,
    Service,
  },

  data() {
    return {
      headers: [
        { text: "Name", value: "name", sortable: true },
        { text: "Description", value: "description", sortable: false },
        { text: "Visibility", value: "visibility", sortable: false },
        { text: "Oncall Service", value: "oncall_service.name", sortable: false },
        { text: "Incident Type", value: "incident_type.name", sortable: false },
        { text: "Storycurve Logging Enabled", value: "storycurve_logging_enabled", sortable: false },
        { text: "Default", value: "default", sortable: true },
        { text: "Enabled", value: "enabled", sortable: true },
        { text: "", value: "data-table-actions", sortable: false, align: "end" },
      ],
    }
  },

  computed: {
    ...mapFields("case_type", [
      "table.options.q",
      "table.options.page",
      "table.options.itemsPerPage",
      "table.options.sortBy",
      "table.options.descending",
      "table.options.filters.project",
      "table.loading",
      "table.rows.items",
      "table.rows.total",
    ]),
    ...mapFields("route", ["query"]),
  },

  created() {
    this.project = [{ name: this.query.project }]

    this.getAll()

    this.$watch(
      (vm) => [vm.page],
      () => {
        this.getAll()
      }
    )

    this.$watch(
      (vm) => [vm.q, vm.itemsPerPage, vm.sortBy, vm.descending, vm.project],
      () => {
        this.page = 1
        this.$router.push({ query: { project: this.project[0].name } })
        this.getAll()
      }
    )
  },

  methods: {
    ...mapActions("case_type", ["getAll", "createEditShow", "removeShow"]),
  },
}
</script>
